import {
  Container,
  // Flex,
  // Card,
  // Box,
  Image,
  Group,
  // Text,
  // Badge,
  // Button,
  // Divider,
  // ActionIcon,
  // Fieldset,
  // Grid,
  // NumberInput,
  // Alert,
  // Title,
  AppShell,
  // Burger,
  em,
  useMantineTheme,
  UnstyledButton,
  // Anchor,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import bg from "../../../public/bg1.svg";
import Head from "next/head";
import { Calc } from "@/components/Calc";
// import routes from "@/utils/routes";
// import { useUser } from "@clerk/nextjs";
import { LanguagePicker } from "@/components";
// import { useDisclosure } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";

// import { UserButton } from "@clerk/nextjs";
// import Link from "next/link";
export default function CalcPage() {
  // const { isSignedIn } = useUser();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const theme = useMantineTheme();
  // const [opened, { toggle }] = useDisclosure();
  return (
    <>
      <Head>
        <title>{t("meta.calc.title")}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={t("meta.calc.description")} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta name="theme-color" content="#DDA159" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#DDA159" />
        <meta name="robots" content="all" />
        <link
          rel="canonical"
          href="https://alma-med.com/calc"
          key="canonical"
        />
      </Head>
      <AppShell header={{ height: 60 }} padding="md">
        <AppShell.Header>
          <Container h="100%" size="xl">
            <Group h="100%" justify="space-between">
              <UnstyledButton component="a" href="/" maw={100}>
                <Image
                  src="/logos/full-logo-blue.svg"
                  alt="Alma Obstetrician Healthcare Software"
                  height={28}
                  width={100}
                />
              </UnstyledButton>

              <Group gap={8}>
                <LanguagePicker type="collapsed" size={isMobile ? 30 : 30} />
                {/* <UserButton afterSignOutUrl="/sign-in" /> */}
              </Group>
            </Group>
          </Container>
        </AppShell.Header>

        <AppShell.Main
          styles={{ main: { background: theme.colors.gray[0] } }}
          p={0}
          pt={60}
        >
          <AppShell.Section
            id="calc"
            styles={{
              section: {
                backgroundImage: `url(${bg.src})`,
                backgroundSize: "cover",
                backgroundBlendMode: "overlay",
                backgroundColor: `rgb(255 255 255 / 76%)`,
                minHeight: "calc(100vh - 60px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundAttachment: "fixed",
              },
            }}
            p="sm"
          >
            <Calc />
          </AppShell.Section>
        </AppShell.Main>
      </AppShell>
    </>
  );
}
